import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { putAutopayResponses, deleteAutopayResponses } from "../../utils/data/responseCodeMessages";

const { REACT_APP_API_URL } = process.env;

export const getLoans = createAsyncThunk(
  "GET: /loan",
  async (token, { rejectWithValue }) => {
    const header = { headers: { Authorization: token } };
    const res = await axios
      .get(`${REACT_APP_API_URL}/loan`, header)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        return rejectWithValue({
          status: error.response.status,
          data: error.response.data,
          isFailed: true
        });
      });
    return res;
  }
);

export const putEditAutopay = createAsyncThunk(
  "PUT: /loan/{id}/autopay",
  async (payload, { rejectWithValue }) => {
    const url = `${REACT_APP_API_URL}/loan/${payload?.loan_id}/autopay`;
    const header = { headers: { Authorization: payload?.token } };
    const config = { ...payload.brandDetails };

    const loanId = payload?.loan_id;
    delete payload?.token;
    delete payload?.loan_id;
    delete payload?.brandDetails;

    const response = await axios
      .put(url, payload, header)
      .then((res) => {
        const successResponse = putAutopayResponses(config)[res.status];
        return {
          response: successResponse,
          loan: res.data,
          loanId
        };
      })
      .catch((error) => {
        const status = error?.response?.status || error?.request?.status;
        const data = error?.response?.data;
        const errorResponse = putAutopayResponses(config)[status]
          || putAutopayResponses(config)[data.errorMessage]
          || putAutopayResponses(config)["default"];
        return rejectWithValue(errorResponse);
      });
    return response;
  }
);

export const deleteAutopay = createAsyncThunk(
  "DELETE: /loan/{id}/autopay",
  async (payload, { rejectWithValue }) => {
    const url = `${REACT_APP_API_URL}/loan/${payload?.loan_id}/autopay`;
    const header = { headers: { Authorization: payload?.token } };
    const loanId = payload?.loan_id;

    const response = await axios
      .delete(url, header)
      .then((res) => ({
        data: res.data,
        loanId

      }))
      .catch((error) => {
        const status = error?.response?.status || error?.request?.status;
        const data = error?.response?.data;
        const errorResponse = deleteAutopayResponses()[status]
          || deleteAutopayResponses()[data.errorMessage]
          || deleteAutopayResponses()["default"];
        return rejectWithValue(errorResponse);
      });
    return response;
  }
);

const initialState = {
  isLoading: false,
  list: [],
  apiResponse: {},
  autopay: {
    api: {
      isSubmitting: false,
      failures: 0,
      response: null,
      success: false
    },
    deleted: {
      isSubmitting: false,
      failures: 0,
      response: null,
      success: false,
      loans: []
    }
  }
};

export const loanSlice = createSlice({
  name: "loans",
  initialState,
  reducers: {
    setLoans: (state, action) => {
      state.list = action.payload;
    },
    clearLoansState: (state) => {
      state.list = initialState.list;
    },
    resetAutopayApiResponses: (state) => {
      state.autopay.api.success = false;
      state.autopay.api.response = initialState.autopay.api.response;
      state.autopay.deleted.response = initialState.autopay.deleted.response;
    },
    resetLoans: () => initialState
  },
  extraReducers: {
    // Get Loans
    [getLoans.pending]: (state) => {
      state.isLoading = true;
    },
    [getLoans.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.apiResponse = payload;
      state.list = payload.results;
    },
    [getLoans.rejected]: (state) => {
      state.isLoading = false;
    },
    // Edit Autopay
    [putEditAutopay.pending]: (state) => {
      state.autopay.api.isSubmitting = true;
      state.autopay.api.success = false;
      state.autopay.api.response = initialState.autopay.api.response;
      state.autopay.deleted.response = initialState.autopay.api.response;
    },
    [putEditAutopay.fulfilled]: (state, { payload }) => {
      state.autopay.api.isSubmitting = false;
      state.autopay.api.response = payload.response;
      const index = state.list.findIndex(ele => ele.id === payload.loanId);
      if (index !== -1) {
        state.list[index].autopay = payload.loan;
      }
      state.autopay.api.success = true;
    },
    [putEditAutopay.rejected]: (state, { payload }) => {
      state.autopay.api.isSubmitting = false;
      state.autopay.api.response = payload;
      state.autopay.api.success = false;
      state.autopay.api.failures += 1;
    },
    // Delete Autopay
    [deleteAutopay.pending]: (state) => {
      state.autopay.deleted.isSubmitting = true;
      state.autopay.deleted.success = false;
      state.autopay.deleted.response = initialState.autopay.api.response;
      state.autopay.api.response = initialState.autopay.api.response;
    },
    [deleteAutopay.fulfilled]: (state, { payload }) => {
      state.autopay.deleted.isSubmitting = false;
      state.autopay.deleted.response = payload;
      const index = state.list.findIndex(ele => ele.id === payload.loanId);
      if (index !== -1) {
        delete state.list[index].autopay;
      }
      state.autopay.deleted.loans = [...state.autopay.deleted.loans, payload.loanId];
      state.autopay.deleted.success = true;
    },
    [deleteAutopay.rejected]: (state, { payload }) => {
      state.autopay.deleted.isSubmitting = false;
      state.autopay.deleted.response = payload;
      state.autopay.deleted.success = false;
      state.autopay.deleted.failures += 1;
    }
  }
});

export const {
  clearLoansState,
  resetAutopayApiResponses,
  resetLoans,
  setLoans
} = loanSlice.actions;
export default loanSlice.reducer;
